import React from "react";
import {graphql} from 'gatsby';
import { Seo } from "../components/Seo";
import PageBanner from "../components/PageBanner";
import Stats from "../components/Stats";
import Cta from "../components/Cta";
import Button from "../components/Button";
import { useProjectImage } from "../hooks/use-project-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { hcProjects } from "../utils/hardcodedProjects";
import clsx from "clsx";

const HardcodedProjectImage = ({imageName, description}) => {
  const projectImage = useProjectImage(imageName)[0].node;
  return (
    <GatsbyImage image={getImage(projectImage)}
      alt={description} />
  )
}

const ProductSlider = ({products, speed}) => {
  return (
    <div className="w-full overflow-hidden pb-4">
      <div className="flex whitespace-pre w-[max-content] animate-product-slide"
        style={{animationDuration: `${speed}s`}}>
        {products.map((i,k) => (
          <span key={k}
            className={clsx('font-black mr-6 text-xl', k % 4 === 0 ? null : k % 4 === 1 ? 'text-sourci-teal' : k % 4 === 2 ? 'text-sourci-pink' : 'text-sourci-orange')}>
              {i.product_name}
            </span>
        ))}
        {products.map((i,k) => (
          <span key={k}
            className={clsx('font-black mr-6 text-xl', k % 4 === 0 ? null : k % 4 === 1 ? 'text-sourci-teal' : k % 4 === 2 ? 'text-sourci-pink' : 'text-sourci-orange')}>
              {i.product_name}
            </span>
        ))}
      </div>
    </div>
  )
}

const ProjectsPage = ({data}) => {
  const products = data.allPrismicMixedContentProductsList.edges[0].node.data;
  const m = Math.ceil(products.product_list.length / 3)
  const n = Math.ceil((2 * products.product_list.length) / 3)
  const productsOne = products.product_list.slice(0, m)
  const productsTwo = products.product_list.slice(m, n)
  const productsThree = products.product_list.slice(n, products.product_list.length)
  const sortList = ['Tanzee', 'Vush Stimulation', 'Bach Eyewear'];
  const projects = data.allPrismicMixedContentProject.edges.map(i => ({
    to: i.node.uid,
    name: i.node.data.title,
    src: i.node.data.cover_image.url,
    description: i.node.data.description
  }));
  const projectList = Object.entries([...projects, ...hcProjects]).sort((a,b) => sortList.includes(a[1].name) ? -1 : 1);
  const sortedProjects = [];
  for(let [, project] of projectList) {
    sortedProjects.push(project);
  }
  const prismicData = data.prismicProjectsPage.data;
  const pageBannerData = {
    title: 'Projects',
    titleColor: '#ff6cbf',
    titleParts: prismicData.main_banner_title_parts,
    backgroundColor: '#ffffff',
    video: prismicData.youtube_link.url,
    videoPlaceholder: prismicData.video_place_holder_image.url
  }
  return (
    <main>
      <PageBanner {...pageBannerData} />
      <section className="bg-[#f8f8f8] py-16">
        <div className="xl:container mx-auto px-6 items-start text-center">
          <p className="text-3xl md:text-4xl font-black">
            We make <span className="text-sourci-pink">amazing</span> happen!
          </p>
          <div className="w-full xl:px-32 pt-16">
            <Stats data={prismicData.stats} />
          </div>
        </div>
      </section>
      <section className="bg-white py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <div className="grid lg:grid-cols-3 gap-12">
            {sortedProjects.map(({ name, description, src, imageName, to }, k) => (
              <a key={k}
                className="group"
                href={`/projects/${to}`}>
                <div className="relative w-full h-0 pb-[60%] rounded-3xl overflow-hidden mb-6">
                  {src && <img src={src}
                    className="max-w-none h-full w-auto absolute top-[-9999px] left-[-9999px] right-[-9999px] bottom-[-9999px] m-auto group-hover:scale-105 transition-transform duration-300"
                    alt={description} />}
                  {imageName &&
                    <HardcodedProjectImage imageName={imageName}
                      description={description} />
                  }
                </div>
                <div className="font-black text-2xl mb-4 group-hover:text-sourci-teal transition-colors duration-300">{name}</div>
                <div>{description}</div>
              </a>
            ))}
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="px-8 text-center">
          <h4 className="font-black text-3xl mb-8">500+ Types of Products Manufactured:</h4>
        </div>
        <ProductSlider products={productsOne} speed={products.row_1_speed} />
        <ProductSlider products={productsTwo} speed={products.row_2_speed} />
        <ProductSlider products={productsThree} speed={products.row_3_speed} />
      </section>
      <Cta bg="sourci-teal"
        image={prismicData.cta_banner_image}>
        <div className="lg:col-start-2 text-center">
          <p className="text-4xl md:text-5xl font-black mb-4 text-black">When you want to change altitude, call us.</p>
          <p className="text-4xl md:text-5xl font-black mb-8 text-white">We've got the insights that can help with that.</p>
          <Button label="Get in touch today."
            to="/contact"
            type="link"
            className="bg-sourci-pink hover:bg-sourci-pink-100 transition-colors text-white text-lg md:text-xl font-black" />
        </div>
      </Cta>
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
    allPrismicMixedContentProductsList {
      edges {
        node {
          data {
            product_list {
              product_name
            }
            row_1_speed
            row_2_speed
            row_3_speed
          }
        }
      }
    }
    prismicProjectsPage {
      data {
        cta_banner_image {
          alt
          gatsbyImageData
        }
        main_banner_title_parts {
          text
          color
        }
        meta_description
        meta_title
        stats {
          suffix
          prefix
          gradient_to
          numeric_value
          gradient_from
          caption
        }
        video_place_holder_image {
          url
        }
        youtube_link {
          url
        }
      }
    }
  }
`

export default ProjectsPage;

export function Head({data}) {
  const prismicData = data.prismicProjectsPage.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/projects" />
  )
}

